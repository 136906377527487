import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Container } from "./styles"

const Grid = ({ children }) => {
  return <Container>{children}</Container>
}

export default Grid
