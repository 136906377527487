import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 200px);
  min-height: 340px;
  display: flex;
  align-items: center;
  justify-content: space-between;


  @media (max-width: 767px) {
    flex-direction: column;
    width: initial;
    justify-content: center;
  }

  h1 {
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-size: 90px;
    line-height: 113px;
    width: 545px;
    margin-right: 50px;

    @media (max-width: 767px) {
      font-size: 45px;
      line-height: 60px;
      max-width: initial;
      width: initial;
    }
  }
`;
