import React, { useState, useEffect } from 'react';

import { Container, CardLink } from './styles';

const Card = ({ location }) => {
  const {
    featuredImage, place, city, country, slug, date,
  } = location;
  const { fluid } = featuredImage.childImageSharp;
  const [isNew, setIsNew] = useState('');

  useEffect(() => {
    if (date && Date.now() - new Date(date) < 1000 * 60 * 60 * 24 * 5) {
      setIsNew('new');
    }
  }, []);

  return (
    <CardLink
      to={slug}
      className={isNew}
    >
      <Container fluid={fluid} backgroundColor="#040e18">
        <div>
          <span>
            {city}
            {' '}
            -
            {' '}
            {country}
          </span>
          <h2>{place}</h2>
        </div>
      </Container>
    </CardLink>
  );
};

export default Card;
