import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { Link } from '~/components/Link';

export const Container = styled(BackgroundImage)`
  height: 362px;
  background-color: black;
  border-radius: 18px;
  transition: 0.3s all;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.6);
  overflow: hidden;
  display: flex;

  @media (max-width: 767px) {
    width: 100%;
  }

  &::before,
  &::after {
    border-radius: 18px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.6);
    transition: 0.3s all !important;
  }

  &:hover {
    &::before,
    &::after {
      border-radius: 18px;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.6);
      transition: 0.3s all;
      transform: scale(1.05);
    }
  }

  div {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    padding: 40px 10px 40px 20px;

    &:before {
      content: '';
      background: linear-gradient(0deg, #00000082 30%, transparent 50%);
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0px;
      top: 0px;
      transition: 0.3s all;
    }

    span {
      font-size: 17px;
      line-height: 18px;
      margin-bottom: 5px;
      display: block;
      position: relative;
    }

    h2 {
      font-size: 27px;
      line-height: 115.4%;
      text-transform: uppercase;
      position: relative;
    }
  }
`;

export const CardLink = styled(Link)`
  position: relative;

  &.new:after {
    content: 'NEW';
    position: absolute;
    height: 36px;
    width: 36px;
    color: #fff;
    background: #ca0000;
    top: -12px;
    right: -12px;
    padding: 5px;
    line-height: 36px;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    letter-spacing: 0.8px;
  }
`;
