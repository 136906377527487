import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import Grid from '../components/Grid';
import HeroWrapper from '~/components/HeroWrapper';
import Card from '~/components/Card';
import Footer from '../components/Footer';

const IndexPage = () => {
  const { allMarkdownRemark, site, file } = useStaticQuery(graphql`
    query PostList {
      allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
        edges {
          node {
            frontmatter {
              city
              country
              featuredImage {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              place
              title
              video
              date
            }
            fields {
              slug
            }
          }
        }
      }
      site {
        siteMetadata {
          title
          description
          author
        }
      }
      file(relativePath: { eq: "cover.jpg" }) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
    }
  `);

  const today = new Date();
  const postList = allMarkdownRemark.edges;

  return (
    <Layout>
      <SEO
        title=""
        description={site.siteMetadata.description}
        image={file.childImageSharp.fixed.src}
      />
      <Parallax y={[-40, 40]}>
        <HeroWrapper>
          <h1>Where would you&nbsp;like to be?</h1>
        </HeroWrapper>
      </Parallax>
      <Grid>
        {postList.map(({ node: { frontmatter, fields } }) => {
          const cardDate = new Date(frontmatter.date);
          if (today > cardDate) {
            return (
              <Card
                key={frontmatter.place}
                location={{ ...frontmatter, ...fields }}
              />
            );
          }
        })}
      </Grid>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
