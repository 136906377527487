import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;
