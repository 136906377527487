import React from 'react';
import { Link } from 'gatsby';

import { Container } from './styles';

function Footer() {
  return (
    <Container>
      <Link to="/privacy">Privacy Policy</Link>
    </Container>
  )
}

export default Footer;