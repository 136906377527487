import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(235px, 235px));
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  grid-column-gap: 20px;
  grid-row-gap: 44px;
  justify-content: space-between;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
`
